






import { Component, Prop, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

type Tile = {
  name: string;
};

@Component
export default class IframeComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  constructor() {
    super();

    GA.addCustomDimension(1, `${this.userData?.user.id}`);
    GA.trackView(`iFrame: ${this.tile?.name}`);
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }
}
